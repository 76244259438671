export const MOCK_INVITE_USERS = [
  {
    id: '6604928187',
    name: 'Aurthur Lechmere',
    role: 'Counselor',
    scholarStatus: 'Leadership Scholar',
    classYear: 'Master Year  2',
    awardYear: 2008,
    group: 'Technology',
    state: 'NY',
  },
  {
    id: '5473240771',
    name: 'Sidney Van der Daal',
    role: 'Parent',
    scholarStatus: 'Leadership Scholar',
    classYear: 'College sophomore',
    awardYear: 1993,
    group: 'Stem',
    state: 'FL',
  },
  {
    id: '7055983037',
    name: 'Ethel Lissett',
    role: 'Interviewer',
    scholarStatus: 'HCPI student',
    classYear: 'College senior',
    awardYear: 1994,
    group: 'Technology',
    state: 'CO',
  },
  {
    id: '9320419132',
    name: 'Renelle De Paoli',
    role: 'Student',
    scholarStatus: 'Non-awarded Scholar',
    classYear: 'College freshman',
    awardYear: 2004,
    group: 'Health',
    state: 'FL',
  },
  {
    id: '4568095441',
    name: 'Arvy Tathacott',
    role: 'Volunteer',
    scholarStatus: 'Leadership Scholar',
    classYear: 'Master Year  2',
    awardYear: 1992,
    group: 'Stem',
    state: 'NY',
  },
  {
    id: '5242422726',
    name: 'Abba Hackwell',
    role: 'Counselor',
    scholarStatus: 'Non-awarded Scholar',
    classYear: 'High School freshman',
    awardYear: 2010,
    group: 'Health',
    state: 'MN',
  },
  {
    id: '0658086375',
    name: 'Sandro Bendixen',
    role: 'Volunteer',
    scholarStatus: 'Leadership Scholar',
    classYear: 'Master Year 3',
    awardYear: 1993,
    group: 'Technology',
    state: 'NY',
  },
  {
    id: '1391067312',
    name: 'Bryon Giacomello',
    role: 'Counselor',
    scholarStatus: 'HCPI student',
    classYear: 'Doctorate Year 3',
    awardYear: 1996,
    group: 'Engineering',
    state: 'SC',
  },
  {
    id: '6274031863',
    name: 'Ulla Lantiff',
    role: 'Recommender',
    scholarStatus: 'Leadership Scholar',
    classYear: 'High School senior',
    awardYear: 2002,
    group: 'Science',
    state: 'KS',
  },
  {
    id: '5803728500',
    name: 'Anna MacCawley',
    role: 'Reader',
    scholarStatus: 'Leadership Scholar',
    classYear: 'Master Year  2',
    awardYear: 2001,
    group: 'Health',
    state: 'OH',
  },
];
